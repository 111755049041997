import React from "react";
import "./home.scss";
// import { Button, Col, Image, Input, Row } from "antd";
import {
  accuracyIcon,
  basicIcon,
  bgLaptop,
  copyRightIcon,
  managementIcon,
  menuIcon,
  operationIcon,
  ProcessIcon,
  professionalIcon,
  profileIcon,
  qualityIcon,
  revenueIcon,
  saveTimeIcon,
  securityIcon,
  standardIcon,
  userOneIcon,
  usertwoIcon,
  verifiedIcon,
  webLogo,
  webLogoBlack,
} from "../assets";

const Home = () => {
  return (
    <div className="all_main">
      {/* header */}
      <div className="header_main">
        <div className="header_wrap">
          <div className="logo_main">
            <img src={webLogo} className="logo" alt="logo" />
          </div>
          <ul className="header_menu">
            <li>Home</li>
            <li>About Us</li>
            <li>Pricing</li>
            <li>Contact Us</li>
          </ul>
          <button className="register_btn">
            <a href="mailto:codebrainteam@gmail.com">Register</a>
          </button>
          <img alt="menu" src={menuIcon} className="menu_icon" />
        </div>
      </div>
      {/* dashboard */}
      <div className="dashboard_main">
        <h1 className="dashboard_title">Modernize Your Outlet Masterfully </h1>
        <div className="dashboard_paragraph">
          {" "}
          We help you sell better, manage your entire business, and join the
          digital revolution in one hour or less.{" "}
        </div>
        <div className="dashboard_input">
          <input type="text" className="input" placeholder="Enter E-mail" />
          <button className="get_btn">
            <a href="mailto:codebrainteam@gmail.com">Get Started </a>
          </button>
        </div>
        <div className="bg_laptop_wrap">
          <img alt="bg" src={bgLaptop} className="bg_laptop" />
        </div>
      </div>
      {/* network */}
      <div className="network_main">
        <div className="network_paragraph">
          <h1>
            {" "}
            <span>"</span> POS Systems Enable Businesses To Augment Their
            Strategic Foresight And Operational Wisdom. <span>"</span>
          </h1>
        </div>
        <div className="client_main">
          <div className="client_count">
            <h1>15k+</h1>
            <p>trusted client</p>
          </div>
          <div className="client_count">
            <h1>187</h1>
            <p>Country</p>
          </div>
        </div>
      </div>
      {/* Features */}
      <div className="features_main">
        <div className="features_image">
          <div className="features_image_wrap">
            <img src={bgLaptop} alt="" />
          </div>
        </div>
        <div className="features_content_main_wrap">
          <div className="features_content_main">
            <p className="features_title">Our features</p>
            <div className="features_subtitle">
              Take Your Business to The <span>Next Level</span>
            </div>
            <div className="features_paragraph">
              We Have Designed A Highly Flexible POS System That Can Adapt To
              Meet The Specific Needs Of Any Business.
            </div>
            <div className="features_wrap">
              <img
                alt="features icon"
                src={managementIcon}
                height={22}
                width={22}
              />
              <p>Inventory Management</p>
            </div>
            <div className="features_wrap">
              <img
                alt="features icon"
                src={ProcessIcon}
                height={22}
                width={22}
              />
              <p>Credit Card Processing</p>
            </div>
            <div className="features_wrap">
              <img
                alt="features icon"
                src={profileIcon}
                height={22}
                width={22}
              />
              <p>Create Customer Profiles</p>
            </div>
          </div>
        </div>
      </div>
      {/* above and beyoned */}
      <div className="above_main">
        <div className="above_wrap">
          <div className="above_title">
            Above and beyond a{" "}
            <span>
              akshars<span className="pos">POS</span>
            </span>
          </div>
          <div className="above_paragraph">
            It acts as an interface for engagement and historical tracking,
            equipped with CRM capabilities, helping you nurture relationships
            that lead to increased sales.
          </div>
          <div className="above_details_main">
            <div className="above_details_wrap">
              <img src={saveTimeIcon} alt="" />
              <p>Save Time</p>
            </div>
            <div className="above_details_wrap">
              <img src={accuracyIcon} alt="" />
              <p>Improves Accuracy</p>
            </div>
            <div className="above_details_wrap">
              <img src={operationIcon} alt="" />
              <p>Simplifies operation</p>
            </div>
            <div className="above_details_wrap">
              <img src={revenueIcon} alt="" />
              <p>Boosts Revenue</p>
            </div>
            <div className="above_details_wrap">
              <img src={securityIcon} alt="" />
              <p>Increases Security</p>
            </div>
            <div className="above_details_wrap">
              <img src={qualityIcon} alt="" />
              <p>Better quality</p>
            </div>
          </div>
        </div>
        <div className="user_main">
          <div className="user_wrap">
            <p>
              "We are thoroughly satisfied with the performance of this POS
              application. It streamlines the entire transaction process,
              allowing us to handle customer orders and payments with remarkable
              speed and precision"
            </p>
            <div className="user_prfile_main">
              <img src={userOneIcon} alt="" />
              <div className="user_name_main">
                <div className="company_name">Meghaji bhadiyadra</div>
                <div className="user_name">CEO of Rothwell, United Kingdom</div>
              </div>
            </div>
          </div>
          <div className="user_wrap">
            <p>
              "This application has transformed our company’s ability to manage
              orders and payments with enhanced efficiency and precision. It has
              streamlined our workflow, reducing errors and minimizing
              processing times, which in turn allows us to serve our customers
              more effectively and meet their needs promptly."
            </p>
            <div className="user_prfile_main">
              <img src={usertwoIcon} alt="" />
              <div className="user_name_main">
                <div className="company_name">Ratan josh</div>
                <div className="user_name">Owner of Sreeji Mobile, Gujarat</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* pricing */}
      <div className="pricing_main">
        <div className="pricing_title">Our Pricing</div>
        <div className="pricing_sub_title">
          Select A <span>Price Plan</span> That Fulfills Your Need
        </div>
        <div className="pricing_paragraph">
          In Today's Competitive Landscape We Offer Unrivaled Pricing And An
          Innovative Feature Set, Our CRM Empowers Businesses With The Tools
          They Need To Excel To Stay Ahead Of The Curve.
        </div>
        <div className="pricing_card_main">
          <div className="pricing_card_wrap">
            <div>
              <div className="pricing_image">
                <img src={basicIcon} alt="" />
              </div>
              <div className="plan_title">Basic Plan</div>
              <div className="plan_price">
                <span>$25</span>/month
              </div>
              <hr className="plan_hr" />

              <div className="plan_details">
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Total 02 device connection</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Support only two Device</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>SMS & App Notification</p>
                </div>
              </div>
            </div>
            <button className="plan_btn">Choose Plan</button>
          </div>
          <div className="pricing_card_wrap">
            <div>
              <div className="pricing_image">
                <img src={standardIcon} alt="" />
              </div>
              <div className="plan_title">standard Plan</div>
              <div className="plan_price">
                <span>$55</span>
                <span className="deleted_pricing">$90</span>/month
              </div>
              <hr className="plan_hr" />

              <div className="plan_details">
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Total 04 device connection</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Support three Device</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>SMS & App Notification</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Quick Onboarding Process</p>
                </div>
              </div>
            </div>
            <button className="plan_btn">Choose Plan</button>
          </div>
          <div className="pricing_card_wrap">
            <div>
              <div className="pricing_image">
                <img src={professionalIcon} alt="" />
              </div>
              <div className="plan_title">professional Plan</div>
              <div className="plan_price">
                <span>$148</span>/month
              </div>
              <hr className="plan_hr" />

              <div className="plan_details">
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Support Any Device</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Total 08 device connection</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>SMS & App Notification</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Quick Onboarding Process</p>
                </div>
                <div className="plan_details_wrap">
                  <img src={verifiedIcon} alt="" />
                  <p>Cloud-Based POS</p>
                </div>
              </div>
            </div>
            <button className="plan_btn">Choose Plan</button>
          </div>
        </div>
      </div>
      {/* sales */}
      <div className="sales_main">
        <div className="sales_title">
          Personalized Deployment To Drive Your Success
        </div>
        <div className="sales_paragraph">
          We Design A Customized Strategy That Fits Your Unique Business
          Objectives And Challenges Perfectly For Your Success. We Ensure Every
          Step Of Our Solution Aligns With Your Goals, Making Sure Efficient And
          Effective Results. That's Why We Take A Personalized Approach To
          Deployment, Ensuring That Our Solutions Not Only Meet But Exceed Your
          Expectations.
        </div>
        <button className="sales_button">
          <a href="mailto:codebrainteam@gmail.com">Talk to our Sales team</a>
        </button>
      </div>
      {/* footer */}
      <div className="footer_main">
        <div className="footer_wrap">
          <div className="ready_section_main">
            <div className="footer_logo_main">
              <img src={webLogoBlack} className="footer_logo" alt="logo" />
              <div className="footer_title">Let's Get Started</div>
            </div>
            <div className="footer_paragraph">
              Are You Eager To Explore The Unique Benefits Our Portal Offers?
              Our Unique Advantages Sets Us Apart From The Competition-Prepare
              Yourself To Get Amazed!!
            </div>
            <button className="get_start_btn">
              {" "}
              <a href="mailto:codebrainteam@gmail.com">Let’s start</a>
            </button>
          </div>
          <div className="page_link_main">
            <div className="page_link_list">Home</div>
            <div className="page_link_list">About us</div>
            <div className="page_link_list">Pricing</div>
            <div className="page_link_list">Contact Us</div>
          </div>
          <div className="searching_main">
            <div className="searching_wrap">
              <div>
                <div className="searching_title">Searching For Help?</div>
                <div className="searching_paragraph">
                  Need Help Or Have Questions? Reach Us Out Or Check Out Our FAQ
                  And Help Center For More Details
                </div>
              </div>
              <button className="searching_btn">
                <a href="mailto:codebrainteam@gmail.com">Contact us </a>
              </button>
            </div>
            <div class="privacy_link_main">
              <h4>
                <a href="/">Privacy Policy</a>
              </h4>
              <h4>
                <a href="/">Terms of Use</a>
              </h4>
              <p>Akshar POS © {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
        <div className="footer_copy">
          <img src={copyRightIcon} alt="copy" />
          <p>{new Date().getFullYear()} all rights are reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
